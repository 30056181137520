<template>
  <div class="dh-field">
    <div class="van-hairline--bottom">
      <van-field
        v-model="resultDate"
        readonly
        v-bind="$attrs"
        @click="switch_popup = true"
        class="dh-cell"
      />
      <van-popup position="bottom" v-model="switch_popup">
        <van-datetime-picker
          type="date"
          @cancel="switch_popup = false"
          @confirm="confirm"
          show-toolbar
          v-bind="$attrs"
          v-model="currentDate"
        />
      </van-popup>
    </div>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'selectValue'
  },
  props: {
    selectValue: {
      type: String
    },
    type: {
      type: String,
      default: 'date'
    },
    // 展示的格式化
    format: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      resultDate: '',
      switch_popup: false,
      currentDate: ''
    }
  },
  methods: {
    confirm () {
      this.switch_popup = false
      this.resultDate = this.dateFormat(this.currentDate, 'yyyy-MM-dd')
      this.$emit('input', this.resultDate)
      this.$emit('change', this.resultDate)
    },
    dateFormat: (value, format) => {
      if (!value) return
      if (!(value instanceof Date)) {
        value = new Date(value)
      }
      let o = {
        'M+': value.getMonth() + 1, // month
        'd+': value.getDate(), // day
        'h+': value.getHours(), // hour
        'm+': value.getMinutes(), // minute
        's+': value.getSeconds(), // second
        'q+': Math.floor((value.getMonth() + 3) / 3), // quarter
        S: value.getMilliseconds() // millisecond
      }
      if (!format || format === '') {
        format = 'yyyy-MM-dd hh:mm:ss'
      }
      if (/(y+)/.test(format)) {
        format = format.replace(
          RegExp.$1,
          (value.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      }
      for (let k in o) {
        if (new RegExp('(' + k + ')').test(format)) {
          format = format.replace(
            RegExp.$1,
            RegExp.$1.length === 1
              ? o[k]
              : ('00' + o[k]).substr(('' + o[k]).length)
          )
        }
      }
      return format
    }
  }
}
</script>
<style lang="scss" scoped>
.dh-field {
  padding: 0 16px;
  background: #fff;
  .dh-cell.van-cell {
    padding: 10px 0;
  }
  .dh-cell.van-cell--required::before {
    left: -8px;
  }
  .van-popup {
    border-radius: 20px 20px 0 0;
  }
}
</style>