<template>
  <div class="dh-field">
    <div class="van-hairline--bottom">
      <van-field
        @click="show_switch_popup"
        readonly
        scroll-to-error
        v-bind="$attrs"
        v-model="val_text"
        class="dh-cell"
      />
      <van-popup position="bottom" v-model="switch_popup">
        <van-picker
          :columns="columns"
          @cancel="switch_popup = false"
          @confirm="confirm"
          show-toolbar
        />
      </van-popup>
    </div>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'selectValue',
  },
  props: {
    selectValue: {
      type: [String, Number],
    },
    required: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data () {
    return {
      switch_popup: false,
      val_text: ''
    }
  },
  methods: {
    confirm (value) {
      this.switch_popup = false
      this.val_text = value
      this.$emit('input', value)
      this.$emit('change', value)
    },
    show_switch_popup () {
      if (this.$attrs.disabled == true) {
        return false
      } else {
        this.switch_popup = true
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.dh-field {
  padding: 0 16px;
  background: #fff;
  .dh-cell.van-cell {
    padding: 10px 0;
  }
  .dh-cell.van-cell--required::before {
    left: -8px;
  }
  .van-popup {
    border-radius: 20px 20px 0 0;
  }
}
</style>